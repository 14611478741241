import Swiper from 'swiper';

document.initSwiper = function(element, delay) {
  if (typeof element != 'object') {
    return
  }

  var swiper = new Swiper (element, {
    init: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    lazy: {
      loadPrevNext: true,
    },
    loop: true,
  });

  setTimeout(function(){ swiper.init(); }, delay);
}

document.destroySwiper = function(element) {
  if ((typeof element == 'object') && (typeof element.swiper == 'object')) {
    try {
      element.swiper.destroy();
    }
    catch(err) {
      // Initialization is asynchronous. There is a missing DOM element error when trying to destroy before init.
    }
  }
}

$(document).on('turbolinks:load', function() {
  $('.swiper-container').each(function(index, element) {
    var delay = 500 + (index * 100);
    document.initSwiper(element, delay);
  });
});

$(document).on('turbolinks:before-cache', function() {
  $('.swiper-container').each(function(index, element) {
    document.destroySwiper(element);
  })
});
