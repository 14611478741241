document.addEventListener('turbolinks:before-visit', function () {
  window.turbolinksReferer = location.href;
});

document.refreshNetKg = function() {
  if (typeof window.netKgID != 'undefined') {

    var href = "https://www.net.kg/stat.php?id=" + window.netKgID + "&fromsite=" + window.netKgID;
    var src = "https://www.net.kg/img.php?c=yes&id=" + window.netKgID + "&jscript=1.3&page=" + escape(window.location.href) + "&rand=" + Math.random() + "&refer=" + escape(window.turbolinksReferer || '');

    $('#netkg').html(
      "<a href=\"" + href + "\" target=\"_blank\" rel=\"noopener\"><img alt=\"WWW.NET.KG\" width=\"88\" height=\"31\" src=\"" + src + "\"></a>"
    );
  }
}

document.addEventListener('turbolinks:load', function() {
  // if (typeof gtag == 'function') {
  //   gtag('config', window.googleAnalyticsID, {'page_path': (window.location.pathname + window.location.search)});
  // }

  if (typeof ym == 'function') {
    ym(window.yandexMetrikaID, "hit", location.href, document.title, window.turbolinksReferer);
  }

  // document.refreshNetKg();
});

$(document).on('click', '.redirect_link', function(e){
  // if (typeof gtag == 'function') {
  //   gtag('event', 'redirect', { 'event_category' : 'Car' });
  // }

  if (typeof ym == 'function') {
    ym(window.yandexMetrikaID, 'reachGoal', 'REDIRECT');
  }
});

