$(window).on("keydown", function(e){
  if($("#car_modal").length > 0) {
    switch(e.keyCode) {
      case 39: // right
        var next = $('.car.current').next().find('a.card-link');
        if(next.length > 0) {
          Rails.fire(next[0], 'click');
        }
        break;
      case 37: // left
        var prev = $('.car.current').prev().find('a.card-link');
        if(prev.length > 0) {
          Rails.fire(prev[0], 'click');
        }
        break;
    }
  }
});
